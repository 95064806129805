import React, { useState, useEffect } from 'react';
import { Field, reduxForm } from 'redux-form';
import TextField from './TextField';
// import SelectField from './SelectField';
import SelectSearch from './SelectSearch';
import Checkbox from './Checkbox';
import { Grid, Button, Container, Paper, Typography, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import moment from 'moment';
import { compareObjectString } from '../services/utils';
import 'moment/locale/es';
import { leer } from '../services/api';
import { PAIS_CHILE, PAIS_PERU } from '../utils/constants';

moment.locale('es');

const useStyles = makeStyles({
    areaPaper: {
        padding: '10px 0',
        marginBottom: '20px',
        borderColor: 'rgba(0, 0, 0, 0.3)'
    },
    areaPaperLast: {
        padding: '10px 0',
        borderColor: 'rgba(0, 0, 0, 0.3)'
    },
    notificationRUT: {
        minHeight: '27px',
        textAlign: 'center',
        fontStyle: 'italic'
    },
    textoExterior: {
        fontSize: 10
    },
    textoInterior: {
        fontSize: 12,
        marginBottom: '0.1em'
    }
});

const validate = (values = {}) => {
    const errors = {};
    const requiredFields = [
        'nombre',
        'rut',
        'ciudad_id',
        'comuna_id',
        'direccion',
        'contacto_email',
        'tipo_recurso',
        'clase',
        'email_envio_oc'
    ];
    requiredFields.forEach((field) => {
        if (!values[field]) {
            errors[field] = 'Requerido';
        }
    });

    if (values.paisProveedor === PAIS_CHILE && !checkRut(values.rut)) {
        errors.rut = 'Rut Inválido';
    }
    if (values.paisProveedor === PAIS_PERU && !checkRuc(values.rut)) {
        errors.rut = 'Ruc Inválido';
    }

    if (values.telefono && isNaN(Number(values.telefono))) errors.telefono = 'Ingrese solo números y sin espacios.';
    if (values.contacto_telefono && isNaN(Number(values.contacto_telefono))) errors.contacto_telefono = 'Ingrese solo números y sin espacios.';

    if (values.contacto_email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.contacto_email)) {
        errors.contacto_email = 'Email Inválido';
    }
    if (values.email_envio_oc && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email_envio_oc)) {
        errors.email_envio_oc = 'Email Inválido';
    }
    return errors;
};

const checkRut = (rut = '') => {
    if (rut == null) rut = '';
    // Despejar Puntos
    var valor = rut.replace(/\./g, '');
    // Despejar Guión
    valor = valor.replace(/\-/g, '');
    // Aislar Cuerpo y Dígito Verificador
    var cuerpo = valor.slice(0, -1);
    var dv = valor.slice(-1).toUpperCase();
    // Formatear RUN
    rut = cuerpo + '-' + dv;

    if (cuerpo.length < 7) {
        return false;
    }

    var suma = 0;
    var multiplo = 2;

    // Para cada dígito del Cuerpo
    for (var i = 1; i <= cuerpo.length; i++) {
        // Obtener su Producto con el Múltiplo Correspondiente
        var index = multiplo * valor.charAt(cuerpo.length - i);
        // Sumar al Contador General
        suma = suma + index;
        // Consolidar Múltiplo dentro del rango [2,7]
        if (multiplo < 7) {
            multiplo = multiplo + 1;
        } else {
            multiplo = 2;
        }
    }

    // Calcular Dígito Verificador en base al Módulo 11
    var dvEsperado = 11 - (suma % 11);

    // Casos Especiales (0 y K)
    dv = dv == 'K' ? 10 : dv;
    dv = dv == 0 ? 11 : dv;

    // Validar que el Cuerpo coincide con su Dígito Verificador
    if (dvEsperado != dv) {
        return false;
    }

    return true;
};

const checkRuc = (ruc = '') => {
    return /^10|20/.test(ruc) && ruc.length === 11;
}

const formatoRut = (rut, esChile, esPeru) => {
    if (esChile) {
        var textoRut = rut.replace(/[\W\s\._\-a-jA-Jl-zL-Z]+/g, '');
        var fullRut = '';

        if (textoRut.length <= 1) {
            if (textoRut == 'k') {
                fullRut = 'K';
            } else {
                fullRut = textoRut;
            }
        } else {
            var cont = 0;
            for (var i = textoRut.length - 1; i >= 0; i--) {
                if (i == textoRut.length - 1) {
                    if (textoRut.charAt(i) == 'k') {
                        fullRut = '-K';
                    } else {
                        fullRut = '-' + textoRut.charAt(i);
                    }
                } else {
                    if (cont == 3) {
                        fullRut = '.' + fullRut;
                        cont = 0;
                    }
                    fullRut = textoRut.charAt(i) + fullRut;
                    cont++;
                }
            }
        }
        return fullRut;
    } else if (esPeru) {
        return rut.replace(/[^0-9]/g, "");
    } else {
        return rut;
    }
};

let FormProveedores = (props) => {
    const {
        handleSubmit,
        formProveedores,
        change,
        touch,
        editar,
        idSucEdit,
    } = props;
    const classes = useStyles();
    const [estadoRUT, setEstadoRUT] = useState('inicial');
    const [sucursales, setSucursales] = useState([]);
    const [paises, setPaises] = useState([]);
    const [ciudades, setCiudades] = useState([]);
    const [comunas, setComunas] = useState([]);
    const [clases, setClases] = useState([]);
    const [tiposRecurso, setTiposRecurso] = useState([]);
    const [loading, setLoading] = useState(true);
    const [esChile, setEsChile] = useState(null);
    const [esPeru, setEsPeru] = useState(null);
    // console.log("estado RUT", estadoRUT);

    useEffect(() => {
        Promise.all([
            leer(`/ciudad`),
            leer(`/comuna`),
            leer(`/clase`),
            leer(`/tipo-recurso`),
            leer(`/paises`),
        ]).then(responses => {
            setCiudades(responses[0]);
            setComunas(responses[1]);
            setClases(responses[2]);
            setTiposRecurso(responses[3]);
            setPaises(responses[4].filter(x => x.activo));
            setLoading(false);

            if (!formProveedores?.paisProveedor) {
                setEsChile(null);
                setEsPeru(null);
            } else {
                setEsChile(formProveedores.paisProveedor === PAIS_CHILE);
                setEsPeru(formProveedores.paisProveedor === PAIS_PERU);
            }
        });
        if (editar) {
            if (idSucEdit == false) {
                setEstadoRUT('editar');
            } else {
                setEstadoRUT('editarSucursal');
            }
        }
    }, []);

    useEffect(() => {
        // console.log('cambió pais prov', formProveedores?.paisProveedor);
        if (!formProveedores?.paisProveedor) {
            setEsChile(null);
            setEsPeru(null);
        } else {
            setEsChile(formProveedores.paisProveedor === PAIS_CHILE);
            setEsPeru(formProveedores.paisProveedor === PAIS_PERU);
        }
        setEstadoRUT('inicial');
    }, [formProveedores?.paisProveedor]);

    return (loading ? ''
        : <Container>
            <form noValidate onSubmit={handleSubmit}>
            <Typography variant="h5" className={classes.notificationRUT} gutterBottom>
                {estadoRUT == 'nuevo' && 'Proveedor Nuevo'}
                {estadoRUT == 'existe' && 'Proveedor ya existe, se agregará nueva Sucursal'}
            </Typography>
                <Paper variant="outlined" className={classes.areaPaper}>
                <Grid container>
                    <Grid item xs={12} lg={4}>
                        <Grid container justify="center" alignItems="center">
                            <Field
                                style={{ width: '80%' }}
                                type="text"
                                change={change}
                                touch={touch}
                                name="paisProveedor"
                                label="País"
                                options={paises.sort(compareObjectString('nombre'))}
                                component={SelectSearch}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container>
                    {esChile != null &&
                    <Grid item xs={12} lg={4}>
                        <Grid container justify="center" alignItems="center">
                            <Field
                                required
                                style={{ width: '80%' }}
                                type="text"
                                name="rut"
                                label={esChile ? "RUT" : "ID"}
                                component={TextField}
                                normalize={(texto) => formatoRut(texto, esChile, esPeru)}
                                props={{ disabled: editar}}
                                // onBlur={(e, newValue) => {console.log('hice blur', e, newValue)}}
                                onBlur={(e, newValue) => {
                                    if ((esChile && checkRut(newValue)) || (esPeru && checkRuc(newValue)) || (!esChile && !esPeru && newValue)) {
                                        // console.log(newValue, "es válido, ver si existe");
                                        leer(`/proveedor/encontrar-por-rut?rut=${newValue}`).then(response => {
                                            if (response) {
                                                setEstadoRUT('existe');
                                                let tempSucursales = [];
                                                response.sucursales.forEach(x => {
                                                    tempSucursales.push({...x});
                                                });
                                                console.log(tempSucursales);
                                                setSucursales(tempSucursales);
                                                change('nombre', response.nombre);
                                            } else {
                                                setEstadoRUT('nuevo');
                                                change('nombre', '');
                                            }
                                        });
                                    } else {
                                        // console.log(newValue, "no es válido, ocultar todo");
                                        setEstadoRUT('inicial');
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>
                    }
                    {estadoRUT != 'inicial' &&
                    <Grid item xs={12} lg={4}>
                        <Grid container justify="center" alignItems="center">
                            <Field
                                required
                                style={{ width: '80%' }}
                                type="text"
                                name="nombre"
                                label="Razón Social"
                                component={TextField}
                                props={{ disabled: (estadoRUT == 'existe' || estadoRUT == 'editarSucursal')}}
                            />
                        </Grid>
                    </Grid>
                    }
                    {(estadoRUT == 'nuevo' || estadoRUT == 'editar') &&
                    <Grid item xs={12} lg={4}>
                        <Grid container justify="center" alignItems="center">
                            <Field
                                style={{ width: '80%' }}
                                type="text"
                                name="giro"
                                label="Giro de la Empresa"
                                component={TextField}
                            />
                        </Grid>
                    </Grid>
                    }
                </Grid>
                {(estadoRUT == 'nuevo' || estadoRUT == 'editar') &&
                <Grid container>
                    <Grid item xs={12} lg={4}>
                        <Grid container justify="center" alignItems="center">
                            <Field
                                style={{ width: '80%' }}
                                type="text"
                                name="representante_legal"
                                label="Representante Legal"
                                component={TextField}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <Grid container justify="center" alignItems="center">
                            <Field
                                style={{ width: '80%' }}
                                type="text"
                                name="representante_legal_rut"
                                label={esChile ? "RUT Representante Legal": "ID Representante Legal"}
                                component={TextField}
                                normalize={(texto) => formatoRut(texto, esChile, esPeru)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <Grid container justify="center" alignItems="center">
                            <Field
                                required
                                style={{ width: '80%' }}
                                type="text"
                                change={change}
                                touch={touch}
                                name="tipo_recurso"
                                label="Tipo de Recurso"
                                options={tiposRecurso.map(x => ({ id: x.nombre, nombre: x.nombre }))}
                                component={SelectSearch}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                }
                {(estadoRUT == 'nuevo' || estadoRUT == 'editar') &&
                <Grid container>
                    <Grid item xs={12} lg={4}>
                        <Grid container justify="center" alignItems="center">
                            <Field
                                required
                                style={{ width: '80%' }}
                                // type="text"
                                // change={change}
                                // touch={touch}
                                // name="clase"
                                // label="Clase"
                                // options={[
                                //     {id: "Otro 1", nombre: "Otro 1"},
                                //     {id: "Otro 2", nombre: "Otro 2"},
                                //     {id: "Otro 3", nombre: "Otro 3"},
                                //     {id: "Otro 4", nombre: "Otro 4"},
                                //     {id: "Otro 5", nombre: "Otro 5"},
                                //     {id: "Otro 6", nombre: "Otro 6"}
                                // ]}
                                options={clases.filter(x => x.activo).map(x => ({ id: x.nombre, nombre: x.nombre }))}
                                // component={SelectSearch}
                                multiple
                                limitTags={1}
                                charCountLimit={18}
                                maxTagWidth="140px"
                                size="small"
                                // style={{ width: '149px' }}
                                type="text"
                                change={change}
                                touch={touch}
                                name="clase"
                                label="Clase"
                                // options={
                                //     gerencias
                                //     ? gerencias.data.sort(compareObjectString('codigo')).map(x => ({ ...x, nombre: x.codigo }))
                                //     : []
                                // }
                                component={SelectSearch}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <Grid container justify="center" alignItems="center">
                            <Field
                                style={{ width: '80%' }}
                                type="text"
                                change={change}
                                touch={touch}
                                name="criticidad"
                                label="Criticidad"
                                options={[
                                    {id: "Alta", nombre: "Alta"},
                                    {id: "Media", nombre: "Media"},
                                    {id: "Baja", nombre: "Baja"}
                                ]}
                                component={SelectSearch}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <Grid
                            container
                            justify="flex-start"
                            alignItems="center"
                            style={{ paddingLeft: '10%', paddingTop: 10}}>
                            <Field
                                label="Es Local"
                                name="es_local"
                                component={Checkbox}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                }
                </Paper>
                {estadoRUT == 'existe' && (
                    <Paper variant="outlined" className={classes.areaPaper}>
                        <Typography variant="h6" style={{ textAlign: 'center' }} gutterBottom>
                            {'Sucursales existentes'}
                        </Typography>
                        {sucursales.map((sucData, index) => (
                            <div key={index}>
                                <Typography variant="h6" style={{ textAlign: 'left', fontSize: 12, paddingLeft: 5 }}>
                                    {'Sucursal ' + (index + 1)}
                                </Typography>
                                <Grid container spacing={3} style={{ paddingLeft: 5}}>
                                    <Grid item xs={1}>
                                        <Typography
                                            variant="body2"
                                            color="textSecondary"
                                            component="p"
                                            className={classes.textoExterior}>
                                            País
                                        </Typography>
                                        <Typography
                                            gutterBottom
                                            variant="h6"
                                            component="h2"
                                            className={classes.textoInterior}>
                                            {sucData.pais}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography
                                            variant="body2"
                                            color="textSecondary"
                                            component="p"
                                            className={classes.textoExterior}>
                                            Región
                                        </Typography>
                                        <Typography
                                            gutterBottom
                                            variant="h6"
                                            component="h2"
                                            className={classes.textoInterior}>
                                            {sucData.region}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography
                                            variant="body2"
                                            color="textSecondary"
                                            component="p"
                                            className={classes.textoExterior}>
                                            Ciudad
                                        </Typography>
                                        <Typography
                                            gutterBottom
                                            variant="h6"
                                            component="h2"
                                            className={classes.textoInterior}>
                                            {sucData.ciudad?.nombre || ''}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography
                                            variant="body2"
                                            color="textSecondary"
                                            component="p"
                                            className={classes.textoExterior}>
                                            {esChile ? 'Comuna' : 'Distrito'}
                                        </Typography>
                                        <Typography
                                            gutterBottom
                                            variant="h6"
                                            component="h2"
                                            className={classes.textoInterior}>
                                            {sucData.comuna?.nombre || ''}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography
                                            variant="body2"
                                            color="textSecondary"
                                            component="p"
                                            className={classes.textoExterior}>
                                            Dirección
                                        </Typography>
                                        <Typography
                                            gutterBottom
                                            variant="h6"
                                            component="h2"
                                            className={classes.textoInterior}>
                                            {sucData.direccion}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={3} style={{ paddingLeft: 5 }}>
                                    <Grid item xs={1}>
                                        <Typography
                                            variant="body2"
                                            color="textSecondary"
                                            component="p"
                                            className={classes.textoExterior}>
                                            Teléfono
                                        </Typography>
                                        <Typography
                                            gutterBottom
                                            variant="h6"
                                            component="h2"
                                            className={classes.textoInterior}>
                                            {sucData.telefono}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography
                                            variant="body2"
                                            color="textSecondary"
                                            component="p"
                                            className={classes.textoExterior}>
                                            Página Web
                                        </Typography>
                                        <Typography
                                            gutterBottom
                                            variant="h6"
                                            component="h2"
                                            className={classes.textoInterior}>
                                            {sucData.pagina_web}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography
                                            variant="body2"
                                            color="textSecondary"
                                            component="p"
                                            className={classes.textoExterior}>
                                            Correo electrónico envío OC
                                        </Typography>
                                        <Typography
                                            gutterBottom
                                            variant="h6"
                                            component="h2"
                                            className={classes.textoInterior}>
                                            {sucData.email_envio_oc}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography
                                            variant="body2"
                                            color="textSecondary"
                                            component="p"
                                            className={classes.textoExterior}>
                                            Nombre de Ejecutivo
                                        </Typography>
                                        <Typography
                                            gutterBottom
                                            variant="h6"
                                            component="h2"
                                            className={classes.textoInterior}>
                                            {sucData.contacto_nombre}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography
                                            variant="body2"
                                            color="textSecondary"
                                            component="p"
                                            className={classes.textoExterior}>
                                            Correo electrónico de Ejecutivo
                                        </Typography>
                                        <Typography
                                            gutterBottom
                                            variant="h6"
                                            component="h2"
                                            className={classes.textoInterior}>
                                            {sucData.contacto_email}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography
                                            variant="body2"
                                            color="textSecondary"
                                            component="p"
                                            className={classes.textoExterior}>
                                            Teléfono de Ejecutivo
                                        </Typography>
                                        <Typography
                                            gutterBottom
                                            variant="h6"
                                            component="h2"
                                            className={classes.textoInterior}>
                                            {sucData.contacto_telefono}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Divider variant="middle" style={{ marginTop: 5, marginBottom: 5 }} />
                            </div>
                        ))}
                        {/* <Grid container spacing={3}>
                            
                        </Grid>
                        <Grid container spacing={3}>
                            
                        </Grid> */}
                    </Paper>
                )}
                {(estadoRUT == 'nuevo' || estadoRUT == 'editar') &&
                <Paper variant="outlined" className={classes.areaPaper}>
                <Grid container>
                    <Grid item xs={12} lg={4}>
                        <Grid container justify="center" alignItems="center">
                            <Field
                                style={{ width: '80%' }}
                                type="text"
                                name="banco_nombre"
                                label="Nombre Banco"
                                component={TextField}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <Grid container justify="center" alignItems="center">
                            <Field
                                style={{ width: '80%' }}
                                type="text"
                                name="banco_numero_cuenta"
                                label="Nº de Cuenta"
                                component={TextField}
                            />
                        </Grid>
                    </Grid>
                    {esPeru &&
                    <Grid item xs={12} lg={4}>
                        <Grid container justify="center" alignItems="center">
                            <Field
                                style={{ width: '80%' }}
                                type="text"
                                name="cuentaInterbancaria"
                                label="Cuenta Interbancaria"
                                component={TextField}
                            />
                        </Grid>
                    </Grid>
                    }
                    <Grid item xs={12} lg={4}>
                        <Grid container justify="center" alignItems="center">
                            <Field
                                style={{ width: '80%' }}
                                type="text"
                                change={change}
                                touch={touch}
                                name="banco_tipo_cuenta"
                                label="Tipo de Cuenta"
                                options={[
                                    {id: "Cuenta Corriente", nombre: "Cuenta Corriente"},
                                    {id: "Cuenta Vista", nombre: "Cuenta Vista"}
                                ]}
                                component={SelectSearch}
                            />
                        </Grid>
                    </Grid>
                {/* </Grid> */}
                {/* <Grid container> */}
                    <Grid item xs={12} lg={4}>
                        <Grid container justify="center" alignItems="center">
                            <Field
                                style={{ width: '80%' }}
                                type="text"
                                name="banco_titular_cuenta"
                                label="Titular Cuenta"
                                component={TextField}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <Grid container justify="center" alignItems="center">
                            <Field
                                style={{ width: '80%' }}
                                type="text"
                                name="banco_rut_cuenta"
                                label={esChile ? "RUT Cuenta Bancaria": "ID Cuenta Bancaria"}
                                component={TextField}
                                normalize={(texto) => formatoRut(texto, esChile)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <Grid container justify="center" alignItems="center">
                            <Field
                                style={{ width: '80%' }}
                                type="text"
                                name="banco_email"
                                label="Correo electrónico"
                                component={TextField}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                </Paper>
                }
                {estadoRUT != 'inicial' && estadoRUT != 'editar' &&
                <Paper variant="outlined" className={classes.areaPaper}>
                    {estadoRUT != 'editarSucursal' && (
                        <Typography variant="h6" style={{ textAlign: 'center' }} gutterBottom>
                            {'Nueva Sucursal'}
                        </Typography>
                    )}
                    <Grid container>
                        <Grid item xs={12} lg={4}>
                            <Grid container justify="center" alignItems="center">
                                <Field
                                    style={{ width: '80%' }}
                                    type="text"
                                    name="pais"
                                    label="País"
                                    component={TextField}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} lg={8}>
                            <Grid container justify="center" alignItems="center">
                                <Field
                                    style={{ width: '90%' }}
                                    type="text"
                                    name="region"
                                    label="Región"
                                    component={TextField}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12} lg={4}>
                            <Grid container justify="center" alignItems="center">
                                <Field
                                    required
                                    style={{ width: '80%' }}
                                    type="text"
                                    change={change}
                                    touch={touch}
                                    name="ciudad_id"
                                    label="Ciudad"
                                    options={
                                        esPeru
                                            ? ciudades.filter(x => x.pais === PAIS_PERU).sort(compareObjectString('nombre'))
                                            : ciudades.sort(compareObjectString('nombre'))
                                    }
                                    component={SelectSearch}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <Grid container justify="center" alignItems="center">
                                <Field
                                    required
                                    style={{ width: '80%' }}
                                    type="text"
                                    change={change}
                                    touch={touch}
                                    name="comuna_id"
                                    label={esChile ? "Comuna" : "Distrito"}
                                    options={comunas
                                        .filter((x) => x.ciudad_id === formProveedores.ciudad_id)
                                        .sort(compareObjectString('nombre'))}
                                    component={SelectSearch}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <Grid container justify="center" alignItems="center">
                                <Field
                                    required
                                    style={{ width: '80%' }}
                                    type="text"
                                    name="direccion"
                                    label="Dirección"
                                    component={TextField}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12} lg={4}>
                            <Grid container justify="center" alignItems="center">
                                <Field
                                    style={{ width: '80%' }}
                                    type="text"
                                    name="telefono"
                                    label="Teléfono"
                                    component={TextField}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <Grid container justify="center" alignItems="center">
                                <Field
                                    style={{ width: '80%' }}
                                    type="text"
                                    name="pagina_web"
                                    label="Página Web"
                                    component={TextField}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <Grid container justify="center" alignItems="center">
                                <Field
                                    required
                                    style={{ width: '80%' }}
                                    type="text"
                                    name="email_envio_oc"
                                    label="Correo electrónico envío OC"
                                    component={TextField}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12} lg={4}>
                            <Grid container justify="center" alignItems="center">
                                <Field
                                    style={{ width: '80%' }}
                                    type="text"
                                    name="contacto_nombre"
                                    label="Nombre de Ejecutivo"
                                    component={TextField}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <Grid container justify="center" alignItems="center">
                                <Field
                                    required
                                    style={{ width: '80%' }}
                                    type="text"
                                    name="contacto_email"
                                    label="Correo electrónico de Ejecutivo"
                                    component={TextField}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <Grid container justify="center" alignItems="center">
                                <Field
                                    style={{ width: '80%' }}
                                    type="text"
                                    name="contacto_telefono"
                                    label="Teléfono de Ejecutivo"
                                    component={TextField}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
                }
                {estadoRUT != 'inicial' &&
                <Grid container style={{ marginTop: '15px' }} justify="center" alignItems="center">
                    <Button type="submit" variant="outlined" color="primary">
                        {editar || estadoRUT == 'editarSucursal' ? 'Editar' : 'Agregar'}
                    </Button>
                </Grid>
                }
            </form>
        </Container>
    );
};

FormProveedores = reduxForm({
    form: 'proveedores',
})(FormProveedores);

export default connect((state, ownProps) => ({
    initialValues: {
        paisProveedor: ownProps.proveedorSelected ? ownProps.proveedorSelected.pais : null,
        nombre: ownProps.proveedorSelected
            ? ownProps.proveedorSelected.nombre
            : null,
        rut: ownProps.proveedorSelected
            ? ownProps.proveedorSelected.rut
            : null,
        ciudad_id: ownProps.proveedorSelected
            ? ownProps.proveedorSelected.sucursales.filter(x => x.id === ownProps.idSucEdit)[0]
            ? ownProps.proveedorSelected.sucursales.filter(x => x.id === ownProps.idSucEdit)[0].ciudad_id
                : null
            : null,
        comuna_id: ownProps.proveedorSelected
            ? ownProps.proveedorSelected.sucursales.filter(x => x.id === ownProps.idSucEdit)[0]
            ? ownProps.proveedorSelected.sucursales.filter(x => x.id === ownProps.idSucEdit)[0].comuna_id
                : null
            : null,
        direccion: ownProps.proveedorSelected
            ? ownProps.proveedorSelected.sucursales.filter(x => x.id === ownProps.idSucEdit)[0]
                ? ownProps.proveedorSelected.sucursales.filter(x => x.id === ownProps.idSucEdit)[0].direccion
                : null
            : null,
        email_envio_oc: ownProps.proveedorSelected
            ? ownProps.proveedorSelected.sucursales.filter(x => x.id === ownProps.idSucEdit)[0]
                ? ownProps.proveedorSelected.sucursales.filter(x => x.id === ownProps.idSucEdit)[0].email_envio_oc
                : null
            : null,
        telefono: ownProps.proveedorSelected
            ? ownProps.proveedorSelected.sucursales.filter(x => x.id === ownProps.idSucEdit)[0]
                ? ownProps.proveedorSelected.sucursales.filter(x => x.id === ownProps.idSucEdit)[0].telefono
                : null
            : null,
        giro: ownProps.proveedorSelected
            ? ownProps.proveedorSelected.giro
            : null,
        representante_legal: ownProps.proveedorSelected
            ? ownProps.proveedorSelected.representante_legal
            : null,
        representante_legal_rut: ownProps.proveedorSelected
            ? ownProps.proveedorSelected.representante_legal_rut
            : null,
        tipo_recurso: ownProps.proveedorSelected
            ? ownProps.proveedorSelected.tipo_recurso
            : null,
        clase: ownProps.proveedorSelected
            ? ownProps.proveedorSelected.clase.split(',')
            : null,
        criticidad: ownProps.proveedorSelected
            ? ownProps.proveedorSelected.criticidad
            : null,
        es_local: ownProps.proveedorSelected
            ? ownProps.proveedorSelected.es_local
            : null,
        banco_nombre: ownProps.proveedorSelected
            ? ownProps.proveedorSelected.banco_nombre
            : null,
        banco_numero_cuenta: ownProps.proveedorSelected
            ? ownProps.proveedorSelected.banco_numero_cuenta
            : null,
        cuentaInterbancaria: ownProps.proveedorSelected?.datosBanco
            ? ownProps.proveedorSelected.datosBanco.cuentaInterbancaria
            : null,
        banco_tipo_cuenta: ownProps.proveedorSelected
            ? ownProps.proveedorSelected.banco_tipo_cuenta
            : null,
        banco_titular_cuenta: ownProps.proveedorSelected
            ? ownProps.proveedorSelected.banco_titular_cuenta
            : null,
        banco_rut_cuenta: ownProps.proveedorSelected
            ? ownProps.proveedorSelected.banco_rut_cuenta
            : null,
        banco_email: ownProps.proveedorSelected
            ? ownProps.proveedorSelected.banco_email
            : null,
        pais: ownProps.proveedorSelected
            ? ownProps.proveedorSelected.sucursales.filter(x => x.id === ownProps.idSucEdit)[0]
            ? ownProps.proveedorSelected.sucursales.filter(x => x.id === ownProps.idSucEdit)[0].pais
                : null
            : null,
        region: ownProps.proveedorSelected
            ? ownProps.proveedorSelected.sucursales.filter(x => x.id === ownProps.idSucEdit)[0]
            ? ownProps.proveedorSelected.sucursales.filter(x => x.id === ownProps.idSucEdit)[0].region
                : null
            : null,
        pagina_web: ownProps.proveedorSelected
            ? ownProps.proveedorSelected.sucursales.filter(x => x.id === ownProps.idSucEdit)[0]
                ? ownProps.proveedorSelected.sucursales.filter(x => x.id === ownProps.idSucEdit)[0].pagina_web
                : null
            : null,
        contacto_nombre: ownProps.proveedorSelected
            ? ownProps.proveedorSelected.sucursales.filter(x => x.id === ownProps.idSucEdit)[0]
                ? ownProps.proveedorSelected.sucursales.filter(x => x.id === ownProps.idSucEdit)[0].contacto_nombre
                : null
            : null,
        contacto_email: ownProps.proveedorSelected
            ? ownProps.proveedorSelected.sucursales.filter(x => x.id === ownProps.idSucEdit)[0]
                ? ownProps.proveedorSelected.sucursales.filter(x => x.id === ownProps.idSucEdit)[0].contacto_email
                : null
            : null,
        contacto_telefono: ownProps.proveedorSelected
            ? ownProps.proveedorSelected.sucursales.filter(x => x.id === ownProps.idSucEdit)[0]
                ? ownProps.proveedorSelected.sucursales.filter(x => x.id === ownProps.idSucEdit)[0].contacto_telefono
                : null
            : null,
    },
    enableReinitialize: true,
    validate,
}))(FormProveedores);
